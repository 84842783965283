/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class Unsubscribed extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Désinscription de la Newsletter LGBTQ')}
          {seo.description('Votre choix a été enregistré.')}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW Désinscription en FRANCAIS */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Fri Jun 11 2021 17:31:53 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Merci - FRANCAIS</title>
          <meta content="Merci - FRANCAIS" property="og:title" />
          <meta content="Merci - FRANCAIS" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.5090e2a11.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div>
            <div className="divdeslangages" />
            <div className="div-block" />
            <div className="divthankyou">
              <h1 className="webflowthankyou">Compris !</h1>
              <h2 className="webflowheading2 violet">Votre choix a été pris en compte :)</h2>
            </div>
            <div className="thumbnailzone">
              <a href="https://twitter.com/Flowfo_" target="_blank" className="linkwebflowthumbnail w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5d285eb314c23d6e24cb9f30_twitter.svg"
                  alt=""
                  className="nicepict"
                />
                <div className="webflowtextthumbnail">@flowfo_</div>
              </a>
              <a
                href="https://www.facebook.com/flowfo.me/"
                target="_blank"
                className="linkwebflowthumbnail w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5feae0764df4280af690f0f0_facebook.png"
                  alt=""
                  className="nicepict"
                />
                <div className="webflowtextthumbnail">@flowfo.me</div>
              </a>
              <a
                href="https://www.instagram.com/flowfo.me/"
                target="_blank"
                className="linkwebflowthumbnail w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5feae0764df428c21390f0f2_instagram.png"
                  alt=""
                  className="nicepict"
                />
                <div className="webflowtextthumbnail">@flowfo.me</div>
              </a>
              <a href="mailto:contact@flowfo.me" target="_blank" className="linkwebflowthumbnail w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5feae0764df428924590f0f4_envelop.png"
                  alt=""
                  className="nicepict"
                />
                <div className="webflowtextthumbnail">contact@flowfo.me</div>
              </a>
            </div>
            <div className="divwithcontent" />
            <div className="divendblank" />
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW Désisnscription en FRANCAIS */}
        </div>
      </>
    );
  }
}

export default Unsubscribed;
